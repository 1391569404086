var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "reqestForm",
      attrs: { "label-position": "top", model: _vm.financeRequestForm },
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "sub-module-title", staticStyle: { padding: "0" } },
            [_vm._v("① Documents")]
          ),
          _c(
            "financer-file-upload",
            _vm._b(
              {
                attrs: {
                  title: "Invoice",
                  fieldName: "requestInvoiceDocuments",
                  isMultiple: false,
                  allowEdit: _vm.allowEdit,
                  disabledUploadOrDeleteFiles: _vm.disabledUploadRequestFiles,
                  uploadFiles: _vm.financeRequestForm.requestInvoiceDocuments,
                },
                on: {
                  setUploadFiles: _vm.setUploadFiles,
                  setRemarksEditFlag: function ($event) {
                    return _vm.$emit("setRemarksEditFlag")
                  },
                },
              },
              "financer-file-upload",
              _vm.$attrs,
              false
            )
          ),
          _c(
            "financer-file-upload",
            _vm._b(
              {
                attrs: {
                  title: "EBDN",
                  fieldName: "ebdnDocuments",
                  isMultiple: true,
                  allowEdit: _vm.allowEdit,
                  disabledUploadOrDeleteFiles: _vm.disabledUploadRequestFiles,
                  uploadFiles: _vm.financeRequestForm.ebdnDocuments,
                },
                on: {
                  setUploadFiles: _vm.setUploadFiles,
                  setRemarksEditFlag: function ($event) {
                    return _vm.$emit("setRemarksEditFlag")
                  },
                },
              },
              "financer-file-upload",
              _vm.$attrs,
              false
            )
          ),
          _c(
            "financer-file-upload",
            _vm._b(
              {
                attrs: {
                  title: "Order",
                  fieldName: "orderDocuments",
                  isMultiple: true,
                  allowEdit: _vm.allowEdit,
                  disabledUploadOrDeleteFiles: _vm.disabledUploadRequestFiles,
                  uploadFiles: _vm.financeRequestForm.orderDocuments,
                },
                on: {
                  setUploadFiles: _vm.setUploadFiles,
                  setRemarksEditFlag: function ($event) {
                    return _vm.$emit("setRemarksEditFlag")
                  },
                },
              },
              "financer-file-upload",
              _vm.$attrs,
              false
            )
          ),
          _c(
            "div",
            {
              staticClass: "sub-module-title",
              staticStyle: { padding: "20px 0px 15px 0px" },
            },
            [_vm._v("② Financing Information")]
          ),
          _c(
            "div",
            { staticClass: "div-frac-14-avg" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Reference Number",
                    prop: "referenceNo",
                    rules: _vm.rules.notReqRule,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabledRequestForm },
                    model: {
                      value: _vm.financeRequestForm.referenceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.financeRequestForm, "referenceNo", $$v)
                      },
                      expression: "financeRequestForm.referenceNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Delivery Date",
                    prop: "deliveryDate",
                    rules: _vm.rules.reqRule,
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: { disabled: _vm.disabledRequestForm },
                    model: {
                      value: _vm.financeRequestForm.deliveryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.financeRequestForm, "deliveryDate", $$v)
                      },
                      expression: "financeRequestForm.deliveryDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Amount",
                    prop: "amount",
                    rules: _vm.rules.reqGreaterThanZeroRule,
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      controls: false,
                      precision: 3,
                      min: 0,
                      disabled: _vm.disabledRequestForm,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$emit(
                          "onCalculateSummary",
                          _vm.financeRequestForm
                        )
                      },
                    },
                    model: {
                      value: _vm.financeRequestForm.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.financeRequestForm, "amount", $$v)
                      },
                      expression: "financeRequestForm.amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "With Recourse",
                    prop: "withRecourse",
                    rules: _vm.rules.reqRule,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.disabledRequestForm },
                      model: {
                        value: _vm.financeRequestForm.withRecourse,
                        callback: function ($$v) {
                          _vm.$set(_vm.financeRequestForm, "withRecourse", $$v)
                        },
                        expression: "financeRequestForm.withRecourse",
                      },
                    },
                    _vm._l(_vm.WITH_RECOURSE_OPTIONS, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "div-frac-12-spec" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Payment Days",
                    prop: "paymentDays",
                    rules: _vm.rules.reqGreaterThanZeroRule,
                  },
                },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "number",
                        disabled: _vm.disabledRequestForm,
                        placeholder: "Please input number",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit(
                            "onCalculateSummary",
                            _vm.financeRequestForm
                          )
                        },
                      },
                      model: {
                        value: _vm.financeRequestForm.paymentDays,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.financeRequestForm,
                            "paymentDays",
                            _vm._n($$v)
                          )
                        },
                        expression: "financeRequestForm.paymentDays",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("day")])],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Remarks",
                    prop: "remarks",
                    rules: _vm.rules.notReqRule,
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 1,
                      disabled: _vm.disabledRequestForm,
                    },
                    model: {
                      value: _vm.financeRequestForm.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.financeRequestForm, "remarks", $$v)
                      },
                      expression: "financeRequestForm.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "sub-module-title",
              staticStyle: { padding: "20px 0px 15px 0px" },
            },
            [_vm._v("③ Account Receivable")]
          ),
          _c(
            "div",
            { staticClass: "div-frac-14-avg" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Company Name",
                    prop: "companyName",
                    rules: _vm.rules.reqRule,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        loading: _vm.companySelectLoading,
                        "allow-create": "",
                        filterable: "",
                        disabled: _vm.disabledRequestForm,
                      },
                      on: { change: _vm.handleChangePayableCompanyName },
                      model: {
                        value: _vm.financeRequestForm.companyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.financeRequestForm, "companyName", $$v)
                        },
                        expression: "financeRequestForm.companyName",
                      },
                    },
                    _vm._l(
                      _vm.$_.uniqBy(_vm.companyOptionList, "companyName"),
                      function (item) {
                        return _c("el-option", {
                          key: item.companyName,
                          attrs: {
                            label: item.companyName.toUpperCase(),
                            value: item.companyName.toUpperCase(),
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Company Address",
                    prop: "companyAddress",
                    rules: _vm.rules.notReqRule,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabledRequestForm },
                    on: { change: _vm.handleChangePayableInfo },
                    model: {
                      value: _vm.financeRequestForm.companyAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.financeRequestForm, "companyAddress", $$v)
                      },
                      expression: "financeRequestForm.companyAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Person In Charge",
                    prop: "personInChargeName",
                    rules: _vm.rules.notReqRule,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabledRequestForm },
                    on: { change: _vm.handleChangePayableInfo },
                    model: {
                      value: _vm.financeRequestForm.personInChargeName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.financeRequestForm,
                          "personInChargeName",
                          $$v
                        )
                      },
                      expression: "financeRequestForm.personInChargeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Contact Number",
                    prop: "contactNumber",
                    rules: _vm.rules.notReqRule,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: _vm.disabledRequestForm },
                    on: { change: _vm.handleChangePayableInfo },
                    model: {
                      value: _vm.financeRequestForm.contactNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.financeRequestForm, "contactNumber", $$v)
                      },
                      expression: "financeRequestForm.contactNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "Email",
                prop: "emails",
                rules: [
                  {
                    required: true,
                    validator: this.checkEmailsValidation,
                    trigger: ["blur"],
                  },
                ],
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  disabled:
                    _vm.disabledRequestForm ||
                    !_vm.financeRequestForm.companyName,
                },
                on: { change: _vm.handleChangePayableInfo },
                model: {
                  value: _vm.financeRequestForm.emails,
                  callback: function ($$v) {
                    _vm.$set(_vm.financeRequestForm, "emails", $$v)
                  },
                  expression: "financeRequestForm.emails",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }